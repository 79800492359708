export default class Header {
    static init() {
        const newsletterModal = document.getElementById('newsletter-modal');
        const closeButton = document.getElementById('newsletter-modal__close');
        const hasShown = localStorage.getItem('pollinators-newsletter-modal')

        if (!newsletterModal || hasShown === '1') {
            return
        }

        const close = () => {
            newsletterModal.style.display = 'none'
            localStorage.setItem('pollinators-newsletter-modal', '1')
        }

        window.addEventListener('scroll', function onScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > 200) {
                newsletterModal.style.display = 'block'
                window.removeEventListener('scroll', onScroll)
            }
        });

        newsletterModal.addEventListener('click', event => {
            if (event.target && event.target.id && event.target.id === 'newsletter-modal') {
                close()
            }
        })

        if (closeButton) {
            closeButton.addEventListener('click', () => {
                close()
            })
        }
    }
}

