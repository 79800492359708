export default class Header {
    static init() {
        const newsletterForms = document.querySelectorAll('.newsletter-form');

        for (let i = 0; i < newsletterForms.length; i++) {
            const newsletterForm = newsletterForms[i];
            newsletterForm.addEventListener('submit', event => {
                event.preventDefault()

                const loader = newsletterForm.querySelector('.newsletter-form__loader')
                const feedback = newsletterForm.querySelector('.newsletter-form__feedback')
                const success = newsletterForm.querySelector('.newsletter-form__success')
                const fields = newsletterForm.querySelector('.newsletter-form__fields')

                loader.style.display = 'block'
                feedback.style.display = 'none'

                const name = event.target.elements['name'].value
                const email = event.target.elements['email'].value
                const data = new URLSearchParams({
                    action: 'forge_newsletter',
                    nonce: forge_params.nonce,
                    name,
                    email
                })

                fetch(forge_params.ajax_url, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: data
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            feedback.style.display = 'block'
                            feedback.innerHTML = data.message
                        }
                        if (data.success) {
                            success.innerHTML = data.message
                            success.style.display = 'block'
                            fields.style.display = 'none'
                        }

                        loader.style.display = 'none'
                    })
                    .catch(error => {
                        feedback.style.display = 'block'
                        feedback.innerHTML = 'Er is iets mis gegaan, probeer het opnieuw!'
                    })
            })
        }
    }
}

