export default class Hero {
    static init() {
        let busy = false;
        let transitioned = false;
        const hero = document.querySelector('.hero.hero--scroll-effect');

        if (!hero) {
            return;
        }

        const heroTitle = document.querySelector('.hero-title');
        const heroIntro = document.querySelector('.hero-intro');

        if (heroIntro && !heroTitle) {
            heroIntro.style.visibility = 'visible';
            heroIntro.style.opacity = '1';
            heroIntro.style.transform = 'translateY(-50%)';
        }

        if (!heroTitle || !heroIntro) {
            return;
        }

        window.addEventListener('scroll', function onScroll() {
            if (busy) {
                return;
            }

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > 1 && !transitioned) {
                busy = true;
                document.body.classList.add('no-scroll');
                heroTitle.style.opacity = '0';
                heroTitle.style.transform = 'translateY(-200px)';

                setTimeout(function () {
                    document.body.classList.remove('no-scroll');
                }, 1000);

                // Wait for the title to fade out before showing the text
                setTimeout(function() {
                    heroTitle.style.visibility = 'hidden';
                    heroIntro.style.visibility = 'visible';
                    heroIntro.style.opacity = '1';
                    heroIntro.style.transform = 'translateY(-50%)';
                    busy = false;
                    transitioned = true;
                }, 250); // This duration should match the CSS transition duration
            } else if (transitioned && scrollTop === 0) {
                busy = true;
                heroIntro.style.opacity = '0';
                heroIntro.style.transform = 'translateY(-200px)';

                // Wait for the title to fade out before showing the text
                setTimeout(function() {
                    heroIntro.style.visibility = 'hidden';
                    heroTitle.style.visibility = 'visible';
                    heroTitle.style.opacity = '1';
                    heroTitle.style.transform = 'translateY(-50%)';
                    busy = false;
                    transitioned = false;
                }, 250); // This duration should match the CSS transition duration
            }

        });
    }
}

