import './main.css'
import Header from "./util/header";
import Hero from "./util/hero";
import NewsletterModal from "./util/newsletter-modal.js";
import NewsletterForm from "./util/newsletter-form.js";
import Countdown from "./util/countdown.js";

document.addEventListener('DOMContentLoaded', () => {
    Header.init();
    Hero.init();
    NewsletterModal.init();
    NewsletterForm.init();
    Countdown.init();
});
