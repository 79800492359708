export default class Countdown {
    static init() {
        const countdown = document.querySelector('.tp-countdown');

        if (!countdown) {
            return;
        }

        let targetDate = countdown.dataset.date

        if (!targetDate) {
            return;
        }

        const dayValue = countdown.querySelector('.tp-countdown-day')
        const hourValue = countdown.querySelector('.tp-countdown-hour')
        const minuteValue = countdown.querySelector('.tp-countdown-minute')
        const secondValue = countdown.querySelector('.tp-countdown-second')

        targetDate = targetDate + 'Z'

        console.log(targetDate)

        function formatTimePart(value) {
            return value < 10 ? '0' + value : value + ''
        }

        function calculateTimeDifference(targetDate) {
            const now = new Date(); // Current time
            const target = new Date(targetDate); // Convert target date string to Date object
            const difference = target - now; // Difference in milliseconds

            let days = Math.floor(difference / (1000 * 60 * 60 * 24));
            let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((difference % (1000 * 60)) / 1000);

            return { days, hours, minutes, seconds };
        }

        function updateCountdown() {
            const { days, hours, minutes, seconds } = calculateTimeDifference(targetDate);

            if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
                dayValue.innerHTML = formatTimePart(0)
                hourValue.innerHTML = formatTimePart(0)
                minuteValue.innerHTML = formatTimePart(0)
                secondValue.innerHTML = formatTimePart(0)
                clearInterval(intervalId); // Stop the interval
                return;
            }

            dayValue.innerHTML = formatTimePart(days)
            hourValue.innerHTML = formatTimePart(hours)
            minuteValue.innerHTML = formatTimePart(minutes)
            secondValue.innerHTML = formatTimePart(seconds)
        }

        // Update the countdown every second
        const intervalId = setInterval(updateCountdown, 1000);
    }

}

