export default class Header {
    static init() {
        const nav = document.getElementById('main-nav');
        const pageHasHero = document.querySelector('.wp-block-forge-theme-hero');
        const mobileMenu = document.querySelector('.mobile-nav');
        const mobileMenuButton = document.querySelector('.mobile-menu-button');

        if (mobileMenuButton && mobileMenu) {
            mobileMenuButton.addEventListener('click', () => {
                mobileMenu.classList.toggle('mobile-nav--open');
                mobileMenuButton.classList.toggle('mobile-nav--open');
            })
        }

        if (!pageHasHero) {
            nav.classList.add('nav--black')
            document.body.classList.add('no-hero');
            return;
        }

        window.addEventListener('scroll', () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > 50) {
                nav.classList.add('nav--black')
            } else {
                nav.classList.remove('nav--black')
            }
        });
    }
}

